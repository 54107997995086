<template>
  <div v-if="item" class="event">
    <!-- Title -->
    <b-row align-h="center">
      <b-col cols="12">
        <h3
          v-if="item.title && item.title.length < 47"
          class="subscription_title"
          :class="item.title"
        >
          {{ item.title }}
        </h3>

        <h3 v-else class="subscription_title" :class="item.title">
          {{ item.title.substring(0, 47) + ".." }}
        </h3>

        <!-- <p>{{item.description}}</p> -->
      </b-col>
    </b-row>

    <!-- Data -->
    <b-row align-h="between" class="">
      <b-col>
        <b-row :class="item.title">
          <b-col cols="6">
            <p class="subscription_data" v-if="item.cost">{{ item.cost }}₽</p>
          </b-col>

          <b-col cols="6">
            <p class="subscription_data" v-if="item.description">
              {{ item.description }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div>
      <button
        class="main__button"
        @click="toStartChange()"
        v-if="
          startChange ||
          (subscription && subscription.expiration_date == null) ||
          subscription == null
        "
      >
        Начать изменения
      </button>

      <button
        class="main__button"
        @click="subscribe(item.id)"
        v-if="
          subscription &&
          subscription.saved_payment_method == null &&
          !startChange &&
          $route.name != 'User'
        "
      >
        Подписаться
      </button>

      <button
        v-if="
          subscription &&
          subscription.saved_payment_method !== null &&
          !startChange &&
          $route.name != 'User'
        "
        class="main__button"
        @click="changeSubscription(item.id)"
      >
        Выбрать
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "SubCard",
  props: {
    item: Object,
    startChange: Boolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      subscription: (state) => state.user.subscription,
    }),
  },
  methods: {
    ...mapActions(["getUser"]),
    async subscribe(id) {
      if (this.user !== null) {
        this.$store.commit("SET_LOADING", true);

        await axios
          .post("subscription", { subscription_type_id: id })
          .then((res) => {
            this.$store.commit("SET_LOADING", false);
            location.replace(res.data.confirmation_url);
          });
      } else {
        this.$router.push({ name: "Tasks" });
      }
    },
    async toStartChange() {
      this.$router.push({ name: "Tasks" });
    },
    async changeSubscription(id) {
      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post("change_subscription", { subscription_type_id: id });

        await this.getUser();
        this.$router.push({ name: "User" });

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
/* main event */
.event {
  border-radius: $border;
  background-color: $black;
  color: $white;
  margin-bottom: 30px;
}

.event_speaker {
  font-size: 18px;
  margin: 0;
  padding-bottom: 15px;

  font-weight: 300;
  letter-spacing: 2px;
}

.event_datetime_container {
  padding-bottom: 60px;
}
.event_date-time {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  color: $white;
}
.event_date-time.time {
  font-weight: 700;
  font-size: 28px;
  color: $accent;
}

.exit_button {
  position: absolute;
  top: 15px;
  right: 15px;

  background-color: unset;
  border: none;
}

/* button */
.event__button {
  outline: none;
  background-color: rgba($black, 0);

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  padding: 10px 30px 10px 30px;
  width: 100%;

  color: $white;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;

  transition: 100ms linear;
}
.event__button:hover,
.btn-secondary:hover {
  outline: none;

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  background-color: $accent;

  transition: 100ms linear;
  box-shadow: 0px 0px 14px 0px rgba($accent, 0.7);
}
.event__button:focus,
.event__button:active {
  outline: none;

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  transition: 100ms linear;
  box-shadow: 0px 0px 14px 0px rgba($accent, 0.7);
}
.event__button:active {
  outline: none;
  background-color: rgba($black, 0);

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  transition: 100ms linear;
  box-shadow: 0px 0px 14px 0px rgba($accent, 0.7);
}
.subscription_data {
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.subscription_title {
  margin: 0;
  padding: 15px;
  font-weight: 700;
  font-size: 20px;
  border-top-left-radius: $border;
  border-top-right-radius: $border;
}
.subscription_title.Bronze {
  background-color: #dfa174;
}
.subscription_title.Silver {
  background-color: #b6b6b6;
}
.subscription_title.Gold {
  background-color: #dfb97a;
}
</style>
