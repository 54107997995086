var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{staticClass:"event"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[(_vm.item.title && _vm.item.title.length < 47)?_c('h3',{staticClass:"subscription_title",class:_vm.item.title},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('h3',{staticClass:"subscription_title",class:_vm.item.title},[_vm._v(" "+_vm._s(_vm.item.title.substring(0, 47) + "..")+" ")])])],1),_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',[_c('b-row',{class:_vm.item.title},[_c('b-col',{attrs:{"cols":"6"}},[(_vm.item.cost)?_c('p',{staticClass:"subscription_data"},[_vm._v(_vm._s(_vm.item.cost)+"₽")]):_vm._e()]),_c('b-col',{attrs:{"cols":"6"}},[(_vm.item.description)?_c('p',{staticClass:"subscription_data"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]):_vm._e()])],1)],1)],1),_c('div',[(
        _vm.startChange ||
        (_vm.subscription && _vm.subscription.expiration_date == null) ||
        _vm.subscription == null
      )?_c('button',{staticClass:"main__button",on:{"click":function($event){return _vm.toStartChange()}}},[_vm._v(" Начать изменения ")]):_vm._e(),(
        _vm.subscription &&
        _vm.subscription.saved_payment_method == null &&
        !_vm.startChange &&
        _vm.$route.name != 'User'
      )?_c('button',{staticClass:"main__button",on:{"click":function($event){return _vm.subscribe(_vm.item.id)}}},[_vm._v(" Подписаться ")]):_vm._e(),(
        _vm.subscription &&
        _vm.subscription.saved_payment_method !== null &&
        !_vm.startChange &&
        _vm.$route.name != 'User'
      )?_c('button',{staticClass:"main__button",on:{"click":function($event){return _vm.changeSubscription(_vm.item.id)}}},[_vm._v(" Выбрать ")]):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }